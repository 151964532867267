var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "pageHeader" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
      _c(
        "div",
        { staticClass: "buttons" },
        _vm._l(_vm.buttons, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "button",
              class: _vm.selectIndex == index ? "selectStyle" : "",
              style: { opacity: item.value == 5 ? 0 : 1 },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  item.value != 5 ? _vm.selectThis(index) : null
                }
              }
            },
            [_c("img", { attrs: { src: item.img } })]
          )
        }),
        0
      )
    ]),
    _c("div", { staticClass: "pageBody" }, [
      _c("div", {
        staticClass: "bodyTitle",
        domProps: { innerHTML: _vm._s(_vm.body) }
      }),
      _c("div", { staticClass: "videoBox" }, [
        _c(
          "video",
          {
            ref: "video",
            staticStyle: { "object-fit": "contain" },
            attrs: {
              "webkit-playsinline": "true",
              playsinline: "true",
              "x-webkit-airplay": "true",
              "x5-video-player-type": "h5",
              "x5-video-player-fullscreen": "true",
              "x5-video-orientation": "portraint",
              src: _vm.video,
              controls: "controls",
              poster: _vm.cover
            }
          },
          [
            _vm._v(
              "\n        your browser does not support the video tag\n      "
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }