<template>
  <div class="pageContent">
    <div class="pageHeader">
      {{ title }}
      <div class="buttons">
        <div
          class="button"
          v-for="(item, index) in buttons"
          :key="index"
          @click.stop="item.value != 5 ? selectThis(index) : null"
          :class="selectIndex == index ? 'selectStyle' : ''"
          :style="{ opacity: item.value == 5 ? 0 : 1 }"
        >
          <img :src="item.img" />
        </div>
      </div>
    </div>
    <div class="pageBody">
      <div class="bodyTitle" v-html="body"></div>
      <div class="videoBox">
        <video
          ref="video"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
          style="object-fit: contain"
          :src="video"
          controls="controls"
          :poster="cover"
        >
          your browser does not support the video tag
        </video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: 1,
      selectIndex: 0,
    };
  },
  computed: {
    title() {
      let str = "";
      switch (this.lang) {
        case 1:
          str = "部分品牌机型参考：";
          break;
        default:
          str = "Example of Brand models:";
          break;
      }
      return str;
    },
    body() {
      let str = "";
      switch (this.lang) {
        case 1:
          str = "消息接收需开启更多权限，</br> 点击下方视频查看详细操作步骤";
          break;
        default:
          str =
            "To get notifications, you need to turn on more permissions, please check this tutorial videos to know more.";
          break;
      }
      return str;
    },
    video() {
      return this.buttons[this.selectIndex].video;
    },
    cover() {
      return this.buttons[this.selectIndex].cover;
    },
    buttons() {
      return [
        {
          img: require("../../assets/images/notificationSettings/huwei.png"),
          cover:
            this.lang == 1
              ? require("../../assets/images/notificationSettings/huawei-CN.png")
              : require("../../assets/images/notificationSettings/huawei-EN.png"),
          video:
            this.lang == 1
              ? "https://video.melinked.com/huawei-CN.mp4"
              : "https://video.melinked.com/huawei-EN.mp4",
          value: 0,
        },
        {
          img: require("../../assets/images/notificationSettings/oppo.png"),
          cover:
            this.lang == 1
              ? require("../../assets/images/notificationSettings/oppo-CN.png")
              : require("../../assets/images/notificationSettings/oppo-EN.png"),
          video:
            this.lang == 1
              ? "https://video.melinked.com/oppo-CN.mp4"
              : "https://video.melinked.com/oppo-EN.mp4",
          value: 1,
        },
        {
          img: require("../../assets/images/notificationSettings/vivo.png"),
          cover:
            this.lang == 1
              ? require("../../assets/images/notificationSettings/vivo-CN.png")
              : require("../../assets/images/notificationSettings/vivo-EN.png"),
          video:
            this.lang == 1
              ? "https://video.melinked.com/vivo-CN.mp4"
              : "https://video.melinked.com/vivo-EN.mp4",
          value: 2,
        },
        {
          img: require("../../assets/images/notificationSettings/mi.png"),
          cover:
            this.lang == 1
              ? require("../../assets/images/notificationSettings/xiaomi-CN.png")
              : require("../../assets/images/notificationSettings/xiaomi-EN.png"),
          video:
            this.lang == 1
              ? "https://video.melinked.com/xiaomi-CN.mp4"
              : "https://video.melinked.com/xiaomi-EN.mp4",
          value: 3,
        },
        {
          img: require("../../assets/images/notificationSettings/samsumg.png"),
          cover:
            this.lang == 1
              ? require("../../assets/images/notificationSettings/samsung-CN.png")
              : require("../../assets/images/notificationSettings/samsung-EN.png"),
          video:
            this.lang == 1
              ? "https://video.melinked.com/samsung-CN.mp4"
              : "https://video.melinked.com/samsung-EN.mp4",
          value: 4,
        },
        {
          img: require("../../assets/images/notificationSettings/samsumg.png"),
          value: 5,
        },
      ];
    },
  },
  mounted() {
    if (this.$route.query.lang) {
      this.lang = Number(this.$route.query.lang);
    }
  },
  methods: {
    selectThis(index) {
      this.selectIndex = index;
    },
    videoLoadedmetadata() {
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.pageContent
  width: 100%;
  height: 100%;
  color #333333;
  overflow hidden;
  background: #FFFFFF;
  .pageHeader
    width: 100%;
    height: 150px;
    background: #F9F9F9;
    box-sizing: border-box;
    padding 10px 18px 0 18px;
    .buttons
      margin-top: 20px;
      overflow hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .selectStyle
        border: 1px solid #52BD68 !important;
      .button
        width: 100px;
        height: 34px;
        margin-bottom: 10px;
        overflow hidden;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        img
          width: 100%;
          height: 100%;
  .pageBody
    width: 100%;
    .bodyTitle
      box-sizing: border-box;
      padding 30px 50px 0 50px;
      width: 100%;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
    .videoBox
      width: 175px;
      height: 365px;
      background: rgba(0,0,0,0.4);
      margin 24px auto 0 auto;
      border-radius: 6px;
      overflow hidden;
      video
        width: 100%;
        height: 100%;
</style>
